import { getProduct, getProductsByFilter } from "../../api/getProducts";
import { put, call, all, fork, takeLatest, debounce } from 'redux-saga/effects'
import { 
    setProducts,
    setLog,
    setLoaderState,
    setCategories,
    setCollections,
    setUser,
    deleteProductForm,
    setLoginForm,
    setAuth
} from "redux/actions/actionCreator";
import store from "redux/store";
import { getAllCollections } from "api/getData";
import { login, logout } from "api/auth";
import {
    GET_ALL_PRODUCTS,
    GET_ALL_CATEGORIES,
    GET_PRODUCTS_BY_FILTER,
    GET_USER,
    POST_USER,
    LOGOUT,
    CREATE_PRODUCT,
    DELETE_PRODUCT,
    GET_PRODUCT,
    LOGIN,
    POST_CATEGORY,
    EDIT_PRODUCT,
} from "redux/constans";
// import { postProduct } from "api/postProducts";
import { deleteProducts } from "api/deleteProducts";
import { Credentials } from "utils/types/wp_rest_api";
import { createProduct, deleteProduct, editProduct, getAllProducts } from "api/wc/product";
import { product } from "redux/reducers/product";
import { IProduct } from "utils/types/woocomerce";
import { generateUUID } from "utils/generateUUID";
import { createCategory, getAllCategories } from "api/wc/category";

export function* handlerGetProducts() {
    yield put(setLoaderState(true));
    try {
        const products: IProduct[] = yield call(getAllProducts);
        products.forEach( (product: IProduct) => {
            product.uuid = generateUUID();
        });
        yield put(setProducts(products));
    } catch (error) {
        yield put(setLog({error: true, message: 'Products are not found'}));
    }
    yield put(setLoaderState(false));
}

// export function* handlerGetProduct(action: any) {
//     yield put(setLoaderState(true));
//     try {
//         const product: Generator = yield call(getProduct, action.payload);
//         yield put(setProduct(product));
//     } catch (error) {
//         yield put(setLog({error: true, message: 'Product is not found'}));
//     }
//     yield put(setLoaderState(false));
// }

export function* handlerGetProductsByFilter() {
    yield put(setLoaderState(true));
    try {
        const { filters } = store.getState().setFilters;
        const products: Generator = yield call(getProductsByFilter, filters);
        yield put(setProducts(products));
    } catch (error) {
        yield put(setLog({error: true, message: 'Products are not found'}));
    }
    yield put(setLoaderState(false));
}

// export function* handlerPostUser() {
//     yield put(setLoaderState(true));
//     try {
//         const registrationForm = store.getState().registrationForm;

//         const user: Generator = yield call(register, registrationForm);
//     } catch (error) {
//         yield put(setLog(error: true, message: 'User was not registrated'));
//     }
//     yield put(setLoaderState(false));
// }

export function* handlerGetUser() {
    yield put(setLoaderState(true));
    try {
        const loginForm = store.getState().loginForm;

        const user: Generator = yield call(login, loginForm);       
        yield put(setUser(user));
    } catch (error) {
        yield put(setLog({error: true, message: 'User is not found'}));
    }
    yield put(setLoaderState(false));
}

export function* handlerGetCategories() {
    try {
        const categories: Generator = yield call(getAllCategories);
        yield put(setCategories(categories));
        // const collections: Generator = yield call(getAllCollections);
        // yield put(setCollections(collections));
    } catch (error) {
        console.log('Error', error);
    }
}

export function* handlerLogin() {
    try {
        const data: Credentials = store.getState().loginForm;

        const response: string = yield call(login, data);
        if(!response) {
            yield put(setAuth(true));
        } else {
            yield put(setLog({error: true, message: "Failed to login."}));
        }

    } catch (error) {
        console.log('Error', error);
    }
}

export function* handlerLogout() {
    try {
        const response: Generator = yield call(logout);
        if(response) {
            yield put(setLoginForm({username: "", password: ""}));
            yield put(setAuth(false));
        }
    } catch (error) {
        console.log('Error', error);
    }
}

export function* handlerCreateProduct(action: any) {
    yield put(setLoaderState(true));
    try {
        const response: Generator = yield call(createProduct, action.payload);
    } catch (error) {
        yield put(setLog({error: true, message: 'Failed to save the product.'}));
    }
    yield put(setLoaderState(false));
    yield put(setLog({error: false, message: 'The product has been successfully saved.'}));
}

export function* handlerEditProduct(action: any) {
    console.log("Edit Ptoduct gg: ", action)

    yield put(setLoaderState(true));
    try {
        const response: Generator = yield call(editProduct, action.payload.id, action.payload.body);
    } catch (error) {
        yield put(setLog({error: true, message: 'Failed to edit the product.'}));
    }
    yield put(setLoaderState(false));
    yield put(setLog({error: false, message: 'The product has been successfully edited.'}));
}

export function* handlerCreateCategory(action: any) {
    yield put(setLoaderState(true));
    try {
        const response: Generator = yield call(createCategory, action.payload);
    } catch (error) {
        yield put(setLog({error: true, message: 'Failed to save the category.'}));
    }
    yield put(setLoaderState(false));
    yield put(setLog({error: false, message: 'The category has been successfully saved.'}));
}

export function* handlerDeleteProducts(action: any) {
    try {
        const response: Generator = yield call(deleteProduct, action.payload.id, action.payload.options);
        yield handlerGetProducts();
    } catch (error) {
        console.log('Error', error);
    }
}

export function* watchProductsSaga() {
    yield debounce(500, GET_PRODUCTS_BY_FILTER, handlerGetProductsByFilter);
    yield takeLatest(GET_ALL_PRODUCTS, handlerGetProducts);
    // yield takeLatest(GET_PRODUCT, handlerGetProduct);
    yield takeLatest(GET_ALL_CATEGORIES, handlerGetCategories);
    yield takeLatest(GET_USER, handlerGetUser);
    // yield takeLatest(POST_USER, handlerPostUser);
    yield takeLatest(POST_CATEGORY, handlerCreateCategory);
    yield takeLatest(CREATE_PRODUCT, handlerCreateProduct);
    yield takeLatest(EDIT_PRODUCT, handlerEditProduct);
    yield takeLatest(DELETE_PRODUCT, handlerDeleteProducts);
    yield takeLatest(LOGIN, handlerLogin);
    yield takeLatest(LOGOUT, handlerLogout);
}

export default function* rootSaga() {
    yield all([
        fork(watchProductsSaga)
    ]);
}
