import { IProduct, IProductForm, IProductQueryParams } from "utils/types/woocomerce";
import * as http from '../http';
import { saveImage } from "api/wp/media";
import { toJSON } from "utils/toJSON";

const headers = new Headers();

let url = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_WC_PATH}/products?consumer_key=${process.env.REACT_APP_WC_CONSUMER_KEY}&consumer_secret=${process.env.REACT_APP_WC_CONSUMER_SECRET}`;

/**
 * Creates a new product by sending an HTTP POST request to the specified URL.
 * Optionally uploads an image associated with the product and updates the product information accordingly.
 * @param {IProductForm} product - The product information to be created.
 * @param {IProductQueryParams} options - Additional query parameters for the creation process (optional).
 * @returns {Promise<IProduct>} - A promise that resolves to the created product object.
 * The promise is rejected with an error if the product information is not provided or if an error occurs during the creation process.
 */
export const createProduct = async (product: IProductForm, options?: IProductQueryParams): Promise<IProduct> => {
    if (!product) return Promise.reject(new Error("Product is not provided"));
    headers.append("Content-Type", "application/json");

    if(product.file) {
        const mediaRes = await saveImage(product.file);
        if(mediaRes.source_url) product.images[0].src = mediaRes.source_url;
    } else {
        product.images[0].src = "https://dev-lusiegabrille.pantheonsite.io/wp-content/uploads/woocommerce-placeholder.png"
    }
    delete product.file;

    return new Promise<IProduct>((resolve, reject) => {
        http.post(url, toJSON<IProductForm>(product), headers)
            .then(res => res.json())
            .then(data => {
                resolve(data);
            })
            .catch( (error: Error) => {
                console.error(error)
                reject(null);
            });
    });
};

export const editProduct = async (productId: number, product: IProductForm, options?: IProductQueryParams): Promise<IProduct> => {
    console.log("Edit Ptoduct: ", product)
    if (!product) return Promise.reject(new Error("Product is not provided"));
    headers.append("Content-Type", "application/json");

    if(product.file) {
        const mediaRes = await saveImage(product.file);
        if(mediaRes.source_url) product.images[0].src = mediaRes.source_url;
    } else {
        if(!product.images[0].src) product.images[0].src = "https://dev-lusiegabrille.pantheonsite.io/wp-content/uploads/woocommerce-placeholder.png"
    }
    delete product.file;
    const newUrl = url.replace("?", `/${productId}?`);

    return new Promise<IProduct>((resolve, reject) => {
        http.put(newUrl, toJSON<IProductForm>(product), headers)
            .then(res => res.json())
            .then(data => {
                resolve(data);
            })
            .catch( (error: Error) => {
                console.error(error)
                reject(null);
            });
    });
};

/**
 * Retrieves a list of all products by sending an HTTP GET request to the specified URL with optional query parameters.
 * @param {IProductQueryParams} options - Additional query parameters for customizing the retrieval of products (optional).
 * @returns {Promise<IProduct[]>} - A promise that resolves to an array of product objects.
 * The promise is rejected with an error if an issue occurs during the retrieval process.
 */
export const getAllProducts = async (options?: IProductQueryParams): Promise<IProduct[]> => {    
    if(options) {
        let paramsStr: string = "";
        for(let key in options) {
            paramsStr += `&${key}=${options[key as keyof IProductQueryParams]}`
        };
        if(paramsStr) {
            url = url + paramsStr;
    }
    };

    return new Promise<IProduct[]>((resolve, reject) => {
        http.get(url, headers)
            .then(res => res.json())
            .then(data => {
                resolve(data);
            })
            .catch( (error: Error) => {
                console.error(error)
                reject(null);
            });
    });
};

export const deleteProduct = async (id: number, options?: IProductQueryParams): Promise<IProduct[]> => {
    console.log("Options: ", id, options)
    const newUrl = url.replace("?", `/${id}?`);
    if(options) {
        let paramsStr: string = "";
        for(let key in options) {
            paramsStr += `&${key}=${options[key as keyof IProductQueryParams]}`
        };
        if(paramsStr) {
            url = url + paramsStr;
        }
    };

    return new Promise<IProduct[]>((resolve, reject) => {
        http.del(newUrl, headers)
            .then(res => res.json())
            .then(data => {
                resolve(data);
            })
            .catch( (error: Error) => {
                console.error(error)
                reject(null);
            });
    });
};