import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const ProductBtns = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  flex: 1;
`

export const ProductWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  border-top: solid 1px var(--color-primary);
  border-bottom: solid 1px var(--color-primary);
`

export const ProductInfo = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  padding: 5px 0 5px 10px;
  flex: 3;
`

export const ProductImage = styled.img`
  height: 100%;
  flex: 1;
  object-fit: cover;
`

export const ProductName = styled.h3`
  font-size: var(--font-size-normal);
`
export const ProductId = styled.p`
  font-size: var(--font-size-small);
`

export const Edit = styled(NavLink)`
  height: 100%;
  cursor: pointer;
  flex: 1;
  font-size: var(--font-size-grand);
  background-color: var(--color-succesfully);

  &:hover{
    opacity: 0.7;
  }

  &:active {
    opacity: 0.9;
  }
`

export const Delete = styled.button`
  height: 100%;
  cursor: pointer;
  flex: 1;
  font-size: var(--font-size-grand);
  background-color: var(--color-unsuccesfully);

  &:hover{
    opacity: 0.7;
  }

  &:active {
    opacity: 0.9;
  }
`

export const Restore = styled.button`
  height: 100%;
  cursor: pointer;
  flex: 1;
  font-size: var(--font-size-grand);
  background-color: var(--color-succesfully);

  &:hover{
    opacity: 0.7;
  }

  &:active {
    opacity: 0.9;
  }
`
