export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS';
export const SET_PRODUCTS = 'SET_PRODUCTS';

export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const GET_PRODUCT = 'GET_PRODUCT';
export const EDIT_PRODUCT = 'EDIT_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';

export const DELETE_PRODUCT_FORM = 'DELETE_PRODUCT_FORM';
export const SET_PRODUCT_FORM = 'SET_PRODUCT_FORM';

export const SET_FILTERS = 'SET_FILTERS';
export const GET_PRODUCTS_BY_FILTER = 'GET_PRODUCTS_BY_FILTER';
export const GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES';
export const POST_CATEGORY = 'POST_CATEGORY';

export const POST_USER = 'POST_USER';
export const GET_USER = 'GET_USER';
export const SET_USER = 'SET_USER';
export const SET_AUTH = 'SET_AUTH';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SET_LANG = 'SET_LANG';
export const SET_ALL_CATEGORIES = 'SET_ALL_CATEGORIES';
export const SET_ALL_COLLECTIONS = 'SET_ALL_COLLECTIONS';
export const SET_LOADING = 'SET_LOADING';
export const SET_LOGIN_FORM = 'SET_LOGIN_FORM';
export const SET_REGISTRATION_FORM = 'SET_REGISTRATION_FORM';

export const GET_CART = 'GET_CART';
export const SET_CART = 'SET_CART';
export const DELETE_CART = 'DELETE_CART';
export const UPDATE_CART = 'UPDATE_CART';

export const TOGGLE_FILTER = 'TOGGLE_FILTER';

export const SET_LOG = 'SET_LOG';