import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ProductBox, ProductImages, ProductImage, ProductInfo, ProductImageView, Buttons, Editar, Delete } from './ProductDetailsStyle';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GET_PRODUCT } from 'redux/constans';
import { deleteProduct } from 'redux/actions/actionCreator';
import { IProduct } from 'utils/types/woocomerce';

const ProductDetails = (): JSX.Element => {

    const dispatch = useDispatch();
    const { uuid } = useParams();
    const [product, setProduct] = useState<IProduct>();

    const { products, isAuth, error, isLoading } = useSelector( (store: any) => {
        return {
            products: store.products as IProduct[],
            error: store.error,
            isLoading: store.loader.isLoadingData,
            lang: store.lang,
            isAuth: store.setUser && store.setUser.role === 'admin' ? true : false
        }
    });

    useEffect( () => {
        const found = products?.find( product => product.uuid === uuid);
        console.log("Product", products, found, uuid)

        found && setProduct(found);
    }, [products]);

    const getDescription = (str: string) => {
        return product?.description?.replace("<p>", "").replace("</p>", "");
    }

    return (
        <ProductBox>
            <ProductImageView src={product?.images[0].src} alt={product?.name} />
            {/* <ProductImages>
                {renderImages()}
            </ProductImages> */}
            <ProductInfo>
                <p className="product__name">{product?.name}</p>
                <p className="product__price">€ {product?.price}</p>
                <p className="product__price">{product?.categories[0].name}</p>
                <p className="product__description">{product?.description && getDescription(product?.description)}</p>              
            </ProductInfo>
        </ProductBox>
    )
}

export default ProductDetails;