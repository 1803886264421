import { DELETE_PRODUCT, SET_PRODUCTS } from "redux/constans";
import { IProduct } from "utils/types/woocomerce";

const initialState: IProduct[] = [];


export const products = (state = initialState, {type, payload}: any) => {
    switch(type){
        case SET_PRODUCTS:
            return payload;
        default: return state;
    }
};
