import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setLoginForm, login } from 'redux/actions/actionCreator';
import { Form, Input, Label, Button } from './LoginStyle';
import { extractToken } from 'utils/images/getTokenFromCookie';

const Login = (): JSX.Element => {
    const form: HTMLFormElement | null = document.querySelector('#login');

    const { isAuth } = useSelector( (store: {isAuth: boolean}) => {
        return {
            isAuth: store.isAuth,
        }
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect( () => {
        if(document.cookie && extractToken(document.cookie)) navigate("/admin");
    }, [isAuth])

    const getLoginForm = (form: any) => {
        if(!form) return;
        const loginInputs: any = {};
        form.forEach( (defaultValue: string | number, key: string) => {
            loginInputs[key] = defaultValue;
        })
        return loginInputs;
    }

    const handleChange = (data: { username: string, password: string }) => {
        dispatch(setLoginForm(data));
    }

    const handleSubmit = () => {
        dispatch(login());
        form?.reset();
        navigate("/admin");
    }

    return (
            <Form id='login' 
                onChange={ (event) => {
                    event.preventDefault();
                    const formData = form ? new FormData(form) : null;

                    handleChange(getLoginForm(formData))
                }} 
                onSubmit={ async (event) => {
                    event.preventDefault();
                    handleSubmit();
            }}>
                <Input name='username' defaultValue={""} placeholder='Username' />
                <Input name='password' defaultValue={""} type='password' placeholder='Password' />

                <Button type='submit'> <FormattedMessage id='login' /> </Button>
            </Form>
        )
}

export default Login;