
import React, { useState } from 'react';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { deleteProductForm, setProductForm } from 'redux/actions/actionCreator';
import { GET_ALL_PRODUCTS, CREATE_PRODUCT } from 'redux/constans';
import { 
  Section,
  Label,
  Input,
  Img,
  ImageBox,
  Button,
  Error
} from './StyleInput'; 

const InputImage = ({setFileToForm, deleteFile, url}: {setFileToForm: Function, deleteFile: Function, url: string}): JSX.Element => {
  const [image, setImage] = useState("");
  const [file, setFile] = useState<File>();

  useEffect( () => {
    setImage(url);
  }, [url])

  useEffect( () => {
    if(file) {
      setPreview(file);
      setFileToForm(file);
    }
  }, [file])

  const onSelectFile = (event: any) => {
    setFile(event.target.files[0]);
    // FOR BUG IN CHROME
    // event.target.value = "";
  };

  const setPreview = (file: File) => {
    const imageURL: string = URL.createObjectURL(file);
    setImage((previousImages) => previousImages.concat(imageURL));
  }

  function deleteHandler(image: string, e: any) {
    e.preventDefault();
    setImage("");
    deleteFile();
    URL.revokeObjectURL(image);
  }

  return (
    <Section>
      {image.length > 0 ? (
        <>
        <ImageBox>
          <Img src={image} alt="upload" />
          <Button onClick={(e) => deleteHandler(image, e)}>
            delete image
          </Button>
        </ImageBox>
        </>
      ) : (
        <Label>
          + Add Image
          <Input
            type="file"
            name="images"
            accept="image/*"
            onChange={ (e) => {
              onSelectFile(e)
            }}
          />
        </Label>
      )}
    </Section>
  );
}

export default InputImage;
