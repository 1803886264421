import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { IProduct } from '../../utils/types/woocomerce'
import { Delete, Edit, ProductBtns, ProductId, ProductImage, ProductInfo, ProductName, ProductWrapper, Restore } from './ProductRowStyle';
import { deleteProduct } from 'redux/actions/actionCreator';

const Product = ({product}: { product: IProduct}): JSX.Element => {
    const [checked, setChecked] = useState(false);

    const dispatch = useDispatch();

    const handleDelete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        dispatch(deleteProduct({id: product.id}));
    }

    return (
        <ProductWrapper>
            <ProductImage src={product.images[0].src} alt={product.name} />
            <ProductInfo>
                <ProductName>{product.name}</ProductName>
                <ProductId>#{product.id}</ProductId>
            </ProductInfo>
            <ProductBtns>
                {
                product.status === "trash"
                ?
                <Restore><FormattedMessage id={"restore"}/></Restore>
                :
                <>
                    <Edit to={`products/${product.id}?edit=true`}><FormattedMessage id={"edit"}/></Edit>
                    <Delete onClick={handleDelete}>
                        <FormattedMessage id={"delete"}/>
                    </Delete>
                </>
                }
            </ProductBtns>
        </ProductWrapper>
    )
}

export default Product;