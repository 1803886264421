import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { setLog } from 'redux/actions/actionCreator';
import { GET_ALL_PRODUCTS } from 'redux/constans';
import { IProduct } from 'utils/types/woocomerce';
import { Content, ProductBox, ProductsList } from './AdminProductsListStyle';
import ProductRow from 'components/ProductRow/ProductRow';

const Catalog = (): JSX.Element => {
    const dispatch = useDispatch();
    const { products, error, isLoading } = useSelector( (store: any) => {
        return {
            products: store.products,
            error: store.error,
            isLoading: store.loading,
        }
    });

    useEffect( () => {
        dispatch({type: GET_ALL_PRODUCTS});
    }, [dispatch])

    const renderProducts = (products: IProduct[]): Array<JSX.Element> | undefined => {  
        if(!products?.length) return;      
        const productsList: Array<JSX.Element> = products.map( (product: IProduct) => {
            return <ProductBox key={product.id}><ProductRow key={product.id} product={product}/></ProductBox>
        })
        return productsList;
    }

    return (
        <ProductsList>
            {/* <FilterContainer /> */}
            <Content className={'content'}>
                {renderProducts(products)}
            </Content>
        </ProductsList>
    )
}

export default Catalog;