import { Credentials } from 'utils/types/wp_rest_api';
import * as http from './http';

const tokenUrl = `${process.env.REACT_APP_API_URL}/wp-json/jwt-auth/v1/token`;

/**
 * Performs a login request and stores the token in a cookie.
 *
 * @param {Credentials} body - User credentials for authentication.
 * @returns {Promise<void>} A promise that resolves when the operation is complete.
 * @throws {Error} Throws an error if there is an issue with the request or the response.
 */
export const login = async (body: Credentials): Promise<string | undefined> => {
    const headers = new Headers({
        "Content-Type" : "application/json"
    })

    return http.post(tokenUrl, JSON.stringify(body), headers)
        .then( res => res.json())
        .then( data => {
            if(data.token) {
                document.cookie = `token=${data.token}; max-age=${864000}; path=/; samesite=strict;`;
            } else {
                return data.message;
            }
        })
        .catch( (err: Error) => {
            throw new Error(err.message);
        })
};

/**
 * Logs the user out by clearing the authentication cookie.
 *
 * @returns {boolean} Returns true to indicate a successful logout.
 */
export const logout = (): boolean => {
    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; samesite=strict;';
    return true;
};

// export const register = async (data: any) => {
//     const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/register`, {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//             'Accept': 'application/json',
//         },
//         body: JSON.stringify(data)
//     });
//     return await res.json();
// }
