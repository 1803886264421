import styled from "styled-components";
import { Link as BaseLink } from 'react-router-dom';
 


export const AdminContainer = styled.div`
    width: calc(100% - 20px);
    display: flex;
    justify-content: space-between;
    margin: 10px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 0px 6px -2px rgb(0 0 0);
`

export const Menu = styled.menu`
    max-height: 100%;
    width: 200px;
    display: flex;
    padding: 5px;
    border-radius: 15px;
    background-color: var(--color-white);
`

export const Nav = styled.nav`
    width: 100%;
    display: flex;
    background-color: var(--color-primary);
    border-radius: 12px 0 0 12px;
    z-index: 99;
`
export const Ul = styled.ul`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
`
export const Li = styled.li`
    display: flex;
    width: 100%;
    justify-content: center;
    margin-right: 10px;
    align-items: center;
    padding: 5px 0;
`

export const Link = styled(BaseLink)`
    width: 100%;
    font-family: 'Ghotic';
    opacity: 0.8;
    text-decoration: none;
    font-size: 16px;
    line-height: 16px;
    color: #000;
    padding: 5px 0 5px 10px;

    &:hover {
        color: ${"#FFF"}
    };
    &:active {
        background-color: var(--color-white);
    };
`
