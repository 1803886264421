import { extractToken } from "utils/images/getTokenFromCookie";

/**
 * Performs an HTTP GET request to the specified URL with the provided headers.
 * @param {string} url - The URL to which the GET request will be made.
 * @param {Headers} headers - The headers of the request, which can be modified to include additional information.
 * @returns {Promise<Response>} - A promise that resolves to a Response object representing the response to the request.
 */
const get = (url: string, headers: Headers): Promise<Response> => {
    return fetch(url, {
        method: 'GET',
        headers
    });
};

/**
 * Performs an HTTP POST request to the specified URL with the provided body data and headers.
 * @param {string} url - The URL to which the POST request will be made.
 * @param {BodyInit} body - The body data of the request, which can be a FormData object, a JSON object, or any other data type supported by the Fetch API standard.
 * @param {Headers} headers - The headers of the request, which can be modified before the request to add information such as authorization using a token.
 * @returns {Promise<Response>} - A promise that resolves to a Response object representing the response to the request.
 */
const post = (url: string, body: BodyInit, headers: Headers): Promise<Response> => {
    if(document.cookie) {
        const token = extractToken(document.cookie);
        token && headers.append("Authorization", `Bearer ${token}`);
    }

    return fetch(url, {
        method: 'POST',
        headers,
        body
    });
};

/**
 * Performs an HTTP PUT request to the specified URL with the provided body data and headers.
 * @param {string} url - The URL to which the PUT request will be made.
 * @param {BodyInit} body - The body data of the request, which can be a FormData object, a JSON object, or any other data type supported by the Fetch API standard.
 * @param {Headers} headers - The headers of the request, which can be modified before the request to add information such as authorization using a token.
 * @returns {Promise<Response>} - A promise that resolves to a Response object representing the response to the request.
 */
const put = (url: string, body: BodyInit, headers: Headers): Promise<Response> => {
    const token = document.cookie.split(";")[0].replace("token=", "");
    token && headers.append("Authorization", `Bearer ${token}`);

    return fetch(url, {
        method: 'PUT',
        headers,
        body
    });
};

/**
 * Performs an HTTP DELETE request to the specified URL with the provided headers, and optionally includes an authorization token.
 * @param {string} url - The URL to which the DELETE request will be made.
 * @param {Headers} headers - The headers of the request, which can be modified to include additional information.
 * @returns {Promise<Response>} - A promise that resolves to a Response object representing the response to the request.
 */
const del = (url: string, headers: Headers): Promise<Response> => {
    const token = document.cookie.split(";")[0].replace("token=", "");
    token && headers.append("Authorization", `Bearer ${token}`);

    return fetch(url, {
        method: 'DELETE',
        headers
    });
};

export { get, post, put, del }