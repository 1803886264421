import { SET_AUTH } from "redux/constans";

const initialState: boolean = false

export const isAuth = (state = initialState, {type, payload}: {type: string, payload: boolean}) => {
    switch(type){
        case SET_AUTH:
            console.log("isAuth", payload);
            return payload;
        default: return state;
    }
};
