import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { deleteProduct, setCart, updateCart } from 'redux/actions/actionCreator';
import { IProduct } from '../../utils/types/woocomerce'
import {
    NavLink,
    ProductHover,
    ProductImage,
    ProductInfo
 } from './ProductStyle';

const Product = ({product}: { product: IProduct}): JSX.Element => {
    const [checked, setChecked] = useState(false);

    const dispatch = useDispatch();

    return (
        <NavLink to={"/products/" + product.uuid} key={product.id} state={product.uuid} >
            <ProductImage src={product.images[0].src} alt={product.name} />
            <ProductHover>
                <ProductInfo>
                    <p className="product__name">{product.name}</p>
                    <p className="product__price">€ {product.price}</p>
                </ProductInfo>
            </ProductHover>
        </NavLink>
    )
}

export default Product;