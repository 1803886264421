import { SET_LOG } from "redux/constans";
import { ILog } from "utils/types/currents";

const initialState: ILog = {error: false, message: ""};


export const log = (state = initialState, {type, payload}: {type: string, payload: ILog}) => {
    switch(type){
        case SET_LOG:
            return payload;
        default: return state;
    }
};
