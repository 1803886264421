import { SET_LOADING } from "redux/constans";

const initialState = false


export const loader = (state = initialState, {type, payload}: {type: string, payload: boolean}) => {
    switch(type){
        case SET_LOADING:
            return payload;
        default: return state;
    }
};
