import styled from 'styled-components';

export const FormWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`

export const Form = styled.form`
    width: 300px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    position: relative;

    @media(min-width: 320px) {
        
    }

    @media(min-width: 425px) {}

    @media(min-width: 768px) {}

    @media(min-width: 1024px) {}
`

export const Button = styled.button`
    width: 100%;
    cursor: pointer;
    background-color: light-grey;

    @media(min-width: 320px) {
        width: 100%;
        padding: 10px;
        font-size: 20px;
        border-radius: 5px;
    }

    @media(min-width: 425px) {}

    @media(min-width: 768px) {}

    @media(min-width: 1024px) {}
    
    &:active{
        transform: scale(0.97);
    }

    &:hover{
        background-color: grey;
    }
`

export const FormHeader = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
`

export const FormFooter = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
`

export const ProductImageInput = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 300px;
    position: relative;
    margin-bottom: 10px;
`

export const ProductImage = styled.img`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
`

export const ProductInputs = styled.div`
    width: 100%;
    height: auto;
`

export const Input = styled.input`
    width: 100%;
    z-index: 9;
`

export const Select = styled.select`
    width: 100%;
`

export const Textarea = styled.textarea`
    max-width: 280px;
    min-width: 280px;
    min-height: 75px;
    max-height: 75px;
    padding: 5px;
`