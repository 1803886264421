import { NavLink as BaseNavLink } from 'react-router-dom';
import styled from 'styled-components';
 

type Error = {error: boolean};

export const WrapperBox = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(100, 100, 100, 0.7);
    z-index: 9999;
`

export const LogBox = styled.div`
    width: 250px;
    height: 250px;
`
export const MessageBox = styled.div<Error>`
    width: 250px;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border-radius: 20px;
    padding: 10px;
    background-color: ${ ({error}) => error ? "var(--color-unsuccesfully)" : "var(--color-succesfully)"};
`

export const Message = styled.p`
    font-size: var(--font-size-grand);
`

export const Button = styled.div<Error>`
    // height: 3px;
    // width: 15px;
    padding: 15px;
    border-radius: 10px;
    background-color: var(--color-primary);
    transition: all 0.3s easy;
    cursor: pointer;
    &:hover {
        background-color: var(--color-hover);
    }
    &:active {
        background-color: var(--color-active);
    }
`