import { combineReducers } from "redux";
import { log } from "./log";
import { loader } from "./loader";
import { setData } from "./setData";
import { products } from "./products";
import { setFilters, toggleFilter } from "./filters";
import { setUser } from "./user";
import { loginForm } from "./loginForm";
import { registrationForm } from "./registration";
import { productForm } from "./productForm";
import { lang } from "./lang";
import { cart } from "./cart";
import { isAuth } from "./auth";
import { categories } from "./categories";

const reducer = combineReducers({
    isAuth,
    lang,
    products,
    setFilters,
    log,
    setData,
    setUser,
    loader,
    loginForm,
    registrationForm,
    productForm,
    categories,
    toggleFilter,
    cart
});

export default reducer;