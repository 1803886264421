import { 
    SET_LANG,
    SET_PRODUCTS,
    SET_LOG,
    SET_LOADING,
    SET_ALL_CATEGORIES,
    SET_ALL_COLLECTIONS,
    SET_FILTERS,
    SET_USER,
    SET_LOGIN_FORM,
    SET_REGISTRATION_FORM,
    SET_PRODUCT_FORM,
    SET_AUTH,
    DELETE_PRODUCT,
    DELETE_PRODUCT_FORM,
    TOGGLE_FILTER,
    LOGIN,
    LOGOUT,
    GET_CART,
    SET_CART,
    DELETE_CART,
    UPDATE_CART,
    EDIT_PRODUCT
} from '../constans';

export const setAuth = (payload: boolean) => ({
    type: SET_AUTH,
    payload,
});

export const setLang = (payload: any) => ({
    type: SET_LANG,
    payload,
});

export const setProducts = (payload: any) => ({
    type: SET_PRODUCTS,
    payload,
});

export const setFilters = (payload: any) => ({
    type: SET_FILTERS,
    payload,
});

export const setUser = (payload: any) => ({
    type: SET_USER,
    payload,
});

export const setLog = (payload: {error: boolean, message: string}) => ({
    type: SET_LOG,
    payload,
});

export const setLoaderState = (payload: boolean) => ({
    type: SET_LOADING,
    payload,
});

export const setCategories = (payload: any) => ({
    type: SET_ALL_CATEGORIES,
    payload,
});

export const setCollections = (payload: any) => ({
    type: SET_ALL_COLLECTIONS,
    payload,
});

export const setLoginForm = (payload: any) => ({
    type: SET_LOGIN_FORM,
    payload,
});

export const setRegistrationForm = (payload: any) => ({
    type: SET_REGISTRATION_FORM,
    payload,
});

export const setProductForm = (payload: any) => ({
    type: SET_PRODUCT_FORM,
    payload,
});

export const deleteProductForm = (payload: any) => ({
    type: DELETE_PRODUCT_FORM,
    payload,
});

export const deleteProduct = (payload: any) => ({
    type: DELETE_PRODUCT,
    payload,
});

export const editProduct = (payload: any) => ({
    type: EDIT_PRODUCT,
    payload,
});

export const toggleFilter = (payload: boolean) => ({
    type: TOGGLE_FILTER,
    payload,
});

export const getCart = (payload: any) => ({
    type: GET_CART,
    payload,
});

export const setCart = (payload: any) => ({
    type: SET_CART,
    payload,
});

export const updateCart = (payload: any) => ({
    type: UPDATE_CART,
    payload,
});

export const login = () => ({
    type: LOGIN
});

export const logout = () => ({
    type: LOGOUT,
});

export const deleteCart = () => ({
    type: DELETE_CART
});