import { ICategory, ICategoryForm, ICategoryQueryParams } from "utils/types/woocomerce";
import * as http from '../http';
import { saveImage } from "api/wp/media";
import { toJSON } from "utils/toJSON";

const headers = new Headers();

let url = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_WC_PATH}/products/categories?consumer_key=${process.env.REACT_APP_WC_CONSUMER_KEY}&consumer_secret=${process.env.REACT_APP_WC_CONSUMER_SECRET}`;

export const createCategory = async (category: ICategoryForm): Promise<ICategory> => {
    if (!category) return Promise.reject(new Error("Category is not provided"));
    headers.append("Content-Type", "application/json");

    if(category.file) {
        const mediaRes = await saveImage(category.file);
        if(mediaRes) category.image.src = mediaRes.source_url;
    } else {
        category.image.src = "https://dev-lusiegabrille.pantheonsite.io/wp-content/uploads/woocommerce-placeholder.png"
    };
    delete category.file;

    return new Promise<ICategory>((resolve, reject) => {
        http.post(url, toJSON<ICategoryForm>(category), headers)
            .then(res => res.json())
            .then(data => {
                resolve(data);
            })
            .catch( (error: Error) => {
                console.error(error)
                reject(null);
            });
    });
};

export const getAllCategories = async (options?: ICategoryQueryParams): Promise<ICategory[]> => {
    if(options) {
        let paramsStr: string = "";
        for(let key in options) {
            paramsStr += `&${key}=${options[key as keyof ICategoryQueryParams]}`
        };
        if(paramsStr) {
            url = url + paramsStr;
    }
    };

    return new Promise<ICategory[]>((resolve, reject) => {
        http.get(url, headers)
            .then(res => res.json())
            .then(data => {
                resolve(data);
            })
            .catch( (error: Error) => {
                console.error(error)
                reject(null);
            });
    });
};