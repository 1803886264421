import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { ILog } from 'utils/types/currents';
import { Button, LogBox, Message, MessageBox, WrapperBox } from './LogStyle';
import gif from 'utils/images/Tag-For-Loading-Bar-Gif-Transparent-Loading-Gif-.gif';
import { setLog } from 'redux/actions/actionCreator';

interface ILogView {
    loading: boolean;
    log: ILog;
}

const Log = ({loading, log}: ILogView):JSX.Element => {

    const dispatch = useDispatch();

    const clickHandler = (e: any) => {
        e.preventDefault();
        dispatch(setLog({error: false, message: ""}));
    }

    return (
        <WrapperBox>
            <LogBox>
                {
                    loading
                    ?
                    <img src={gif} width={"100%"} height={"100%"} />
                    :
                    <MessageBox error={log.error}>
                        <Message>{log.message}</Message>
                        <Button error={log.error} onClick={clickHandler}>Ok</Button>
                    </MessageBox>
                }
            </LogBox>
        </WrapperBox>
    )
}

export default Log;