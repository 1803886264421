import { SET_PRODUCT_FORM, DELETE_PRODUCT_FORM } from "redux/constans";

const initialState = {
    name: '',
    price: '',
    description: ''
}

export const productForm = (state = initialState, {type, payload}: any) => {
    switch(type){
        case SET_PRODUCT_FORM:
            return {
                ...state,
                ...payload
            };
        case DELETE_PRODUCT_FORM:
            return payload;
        default: return state;
    }
};
