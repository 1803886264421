import CryptoJS from 'crypto-js';
import OAuth from 'oauth-1.0a';

const perPage = 100;
let apiUrl = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_WC_PATH}/products`;
apiUrl = apiUrl + `?per_page=${perPage}&consumer_key=${process.env.REACT_APP_WC_CONSUMER_KEY}&consumer_secret=${process.env.REACT_APP_WC_CONSUMER_SECRET}`

export const getAllProducts = async () => {
    const res = await fetch(apiUrl);
    return res.json();
}

export const getProduct = async (id: string) => {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/product/${id}`);    
    return await res.json();
}

export const getProductsByFilter = async (data: any) => {
    const options = {
        method: 'POST',
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(data),
    };

    const res = await fetch(`${process.env.REACT_APP_API_URL}/product/sort`, options);
    
    return await res.json();
}