import { generateUUID } from "utils/generateUUID";
import * as http from '../http';
import { Media } from "utils/types/wp_rest_api";

const url = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_WP_PATH}/media`;
const headers = new Headers();

/**
 * Saves an image file by uploading it to a server using an HTTP POST request.
 * @param {File} file - The image file to be saved.
 * @returns {Promise<Media | Error>} - A promise that resolves to either the saved media object or an error.
 * The media object represents information about the saved image, while the error is thrown if the file is not provided or if an error occurs during the process.
 */
export const saveImage = async (file: File): Promise<Media> => {
    if (!file) return Promise.reject(new Error("File is not provided"));

    return new Promise<Media>((resolve, reject) => {
        const reader = new FileReader();

        const ext = file.name.split(".")[1];
        const uid = generateUUID();
        const newFileName = `${uid}.${ext}`;

        headers.append("Content-Disposition", `attachment; filename=${newFileName}`);

        reader.onloadend = () => {
            http.post(url, reader.result as string, headers)
                .then(res => res.json())
                .then(data => {
                    resolve(data);
                })
                .catch( error => {
                    console.error(error)
                    reject(null);
                });
        };

        reader.readAsArrayBuffer(file);
    });
};

/**
 * Removes an image from the server by sending an HTTP DELETE request.
 * @param {string} uuid - The universally unique identifier (UUID) of the image to be removed.
 * @param {boolean} force - A boolean indicating whether to force the removal even if the image is in use.
 * @returns {Promise<unknown | Error>} - A promise that resolves to either the result of the removal operation or an error.
 * The result can be of any type, and the error is thrown if the UUID is not provided or if an error occurs during the removal process.
 */
export const removeImage = async (uuid: string, force: boolean) => {
    if (!uuid) return Promise.reject(new Error("UUID is not provided"));

    return new Promise<unknown | Error>((resolve, reject) => {
        headers.append("Content-Type", "application/json");
        const delUrl = force ? url + uuid : url + uuid + `?force=${force}`;

        http.del(delUrl, headers)
            .then(res => res.json())
            .then(data => {
                resolve(data);
            })
            .catch( error => {
                reject(error);
            });
    });
}