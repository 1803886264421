import { getProductsByFilter } from 'api/getProducts';
import FilterContainer from 'components/Filter/FilterContainer';
import Product from 'components/Product/Product';
import React, { ReactElement, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { setLog } from 'redux/actions/actionCreator';
import { GET_ALL_PRODUCTS } from 'redux/constans';
import { CatalogBox, Content, ProductBox } from './CatalogStyle';
import { IProduct } from 'utils/types/woocomerce';

const Catalog = (): JSX.Element => {
    const dispatch = useDispatch();
    const { products, error, isLoading } = useSelector( (store: any) => {
        return {
            products: store.products,
            error: store.error,
            isLoading: store.loading,
        }
    });

    useEffect( () => {
        dispatch({type: GET_ALL_PRODUCTS});
    }, [dispatch])

    const renderProducts = (products: IProduct[]): Array<JSX.Element> | undefined => {  
        if(!products?.length) return;      
        const productsList: Array<JSX.Element> = products.map( (product: IProduct) => {
            return <ProductBox key={product.id}><Product key={product.id} product={product}/></ProductBox>
        })
        return productsList;
    }

    return (
        <CatalogBox>
            {/* <FilterContainer /> */}
            <Content className={'content'}>
                {renderProducts(products)}
            </Content>
        </CatalogBox>
    )
}

export default Catalog;