import styled from "styled-components";
import ReactDropdown from 'react-dropdown';

export const Dropdown = styled(ReactDropdown)`
`

export const Options = styled.select`
    
`
// .is-open {

// }

// .is-selected {

// }

// <div class="Dropdown-root sc-iqPaeV isMTLp ">
//     <div class="Dropdown-control" aria-haspopup="listbox">
//         <div class="Dropdown-placeholder ">Español</div>
//         <div class="Dropdown-arrow-wrapper">
//             <span class="Dropdown-arrow"></span>
//         </div>
//     </div>
//     <div class="Dropdown-menu" aria-expanded="true">
//         <div class="Dropdown-option" roles="option" aria-selected="false">English</div>
//         <div class="Dropdown-option" roles="option" aria-selected="false">Русский</div>
//         <div class="Dropdown-option is-selected" role="option" aria-selected="true">Español</div>
//         </div>
//     </div>
// </div>