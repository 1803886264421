import { SET_ALL_CATEGORIES, SET_ALL_COLLECTIONS } from "redux/constans";
import { ICategory } from "utils/types/woocomerce";

const initialState: ICategory[] = []


export const categories = (state = initialState, {type, payload}: any) => {
    switch(type){
        case SET_ALL_CATEGORIES:
            return payload;
        default: return state;
    }
};
