import InputImage from 'components/InputFiles/Images/InputImages';
import React, { FormEventHandler, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { editProduct } from 'redux/actions/actionCreator';
import { 
    FormWrapper,
    Form,
    FormHeader,
    FormFooter,
    ProductImageInput,
    ProductInputs,
    Input,
    Textarea,
    Select,
    Button,
} from './ProductFormStyle';
import { ICategory, IProduct, IProductForm } from 'utils/types/woocomerce';
import { GET_ALL_CATEGORIES, CREATE_PRODUCT, EDIT_PRODUCT } from 'redux/constans';
import { useLocation, useParams } from 'react-router-dom';

const initForm: IProductForm = {
    name: "",
    regular_price: "",
    type: "simple",
    short_description: "",
    categories: [{id: 15}],
    description: "",
    file: undefined,
    images: [{src: ""}]
}

const ProductForm = (): JSX.Element => {

    const dispatch = useDispatch();
    const { id } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const editMode = queryParams.get('edit');

    const { categories, products } = useSelector( (store: any) => {
        return {
            categories: store.categories as ICategory[],
            products: store.products as IProduct[],
        }
    });
    const [productForm, setProductForm] = useState(Object.assign({}, initForm));
    const [product, setProduct] = useState<IProduct>();
    const [file, setFile] = useState<File>();

    const getDescription = (str?: string) => {
        return str?.replace("<p>", "").replace("</p>", "") || "";
    }

    useEffect( () => {
        if(id && editMode) {
            const product = products.find( product => product.id === +id);
            setProduct(product);

            if(product) {
                const editProductForm: IProductForm = {
                    name: product.name,
                    regular_price: product.regular_price,
                    type: product.type,
                    short_description: getDescription(product.short_description),
                    categories: [{id: product.categories[0].id || 15}],
                    description: getDescription(product.description),
                    images: [{src: product.images[0].src || ""}]
                }

                setTimeout( () => {
                    setProductForm(Object.assign({}, editProductForm));
                }, 0)
            }
        }

        dispatch({type: GET_ALL_CATEGORIES});
    }, [])

    useEffect( () => {
        console.log("ProductForm: ", productForm)
    }, [productForm])

    useEffect( () => {
        setProductForm({...productForm, file});
    }, [file])

    const getProductForm = (data: any): IProductForm => {
        const registrationInputs: IProductForm = Object.assign({}, productForm);

        const formData = new FormData(data);

        const id = formData.get("categories") as string;
        
        registrationInputs.name = formData.get("name") as string;
        registrationInputs.regular_price = formData.get("price") as string;
        registrationInputs.description = formData.get("description") as string;
        registrationInputs.categories[0].id = +id;

        return registrationInputs;
    }

    const handleImage = (file: File) => {
        setFile(file);
    }

    const deleteImage = () => {
        setFile(undefined);
    }

    const handleChange = (form: IProductForm) => {
        setProductForm(form);
    }

    const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        dispatch({type: CREATE_PRODUCT, payload: productForm});

        const formElement = document.getElementById('productForm') as HTMLFormElement | null;
        if (formElement) {
            formElement.reset();
            deleteImage();
            setProductForm(initForm);
        }
    }

    const hasChanges = (obj: IProductForm) => {
        let count = 0;
        for(const key in obj) {
            if(key !== "file") {
                product![key as keyof IProduct] === obj[key as keyof IProductForm] || count++
            }
        }
        return !!count;
    }

    const handleEdit = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        if(hasChanges(productForm)) {
            console.log("Work")
            dispatch(editProduct({id: product!.id, body: productForm}));
        }
        // const buff = Object.keys(forms)
        // const products: IProduct[] = [];

        // for (const index of buff) {
        //     products.push({form: forms[index], files: images![index as keyof Image].files})
        // }

        // dispatch({type: CREATE_PRODUCT, payload: products});        
    }

    const getCategories = (categories: ICategory[]) => {
        return categories?.map( (category: ICategory) => {
            if(productForm.categories[0].id === category.id) return <option key={category.id} label={category.name} value={category.id} selected></option>;
            return <option key={category.id} label={category.name} value={category.id}></option>
        })
    }

    return (
        <FormWrapper>
            <Form id="productForm" onChange={ (event: any) => {
                event.preventDefault();
                handleChange(getProductForm(event.target.form));
            }} >
                <FormHeader>
                    <ProductImageInput>
                        {/* {setImages(productForm.imgs)} */}
                        <InputImage url={productForm.images[0].src!} setFileToForm={handleImage} deleteFile={deleteImage} />
                    </ProductImageInput>
                    <ProductInputs>
                        <Input name="name" type="text" placeholder="Name" defaultValue={productForm.name} />
                        <Input name="price" type="number" placeholder="Price" defaultValue={productForm.regular_price} />
                        <Select name="categories" defaultValue={productForm.categories[0].id} onChange={() => {}}>
                            {getCategories(categories)}
                        </Select>
                    </ProductInputs>
                </FormHeader>
                <FormFooter>
                    <Textarea 
                        name="description" 
                        className="input description"
                        placeholder="Description"
                        defaultValue={productForm.description}
                    ></Textarea>
                </FormFooter>
                {
                    editMode
                    ? 
                    <Button onClick={handleEdit} >
                        <FormattedMessage id={"edit"} />
                    </Button>
                    : 
                    <Button onClick={handleSubmit} >
                        <FormattedMessage id={"save"} />
                    </Button>
                }
            </Form>
        </FormWrapper>
    )
}

export default ProductForm;
